import Alpine from 'alpinejs'
import htmx from 'htmx.org'

import lazy from './js/lazy.js'
import animations from './js/animations.js'
import ras from './js/ras.js'
import masonry from './js/masonry.js'

window.Alpine = Alpine
Alpine.start()

ras.init();
lazy.init();
animations.init();
masonry.init();

function htmxBefore(evt) {
}

function htmxAfter(evt) {
  lazy.init();
  animations.init();
  masonry.init();

  if (evt.target.classList.contains('js-htmx-main')) {
    setTimeout(() => {

    }, 500);
    window.scrollTo(0, 0);
  }
}


htmx.on("htmx:beforeSend", function(evt) {
  console.log('htmx:beforeSend');
  htmxBefore(evt);
});

htmx.on("htmx:historyRestore", function(evt) {
  console.log('htmx:historyRestore');
  htmxAfter(evt);
});

htmx.on("htmx:afterSwap", function(evt) {
  console.log('htmx:afterSwap');
  htmxAfter(evt);
});