import Masonry from 'masonry-layout';

const masonry = {
    elements: {
        root: '.js-masonry'
    },
    init() {
        const grid = document.querySelector(this.elements.root);
        const msnry = new Masonry(grid, {
            itemSelector: '.js-masonry-item',
            columnWidth: 200
        });
    }
};

export default masonry